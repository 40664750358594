import flatten from 'flat'
import { ModelTranslations } from '../components/langContext/languagesModel'

const target = {
	authentification: {
		title: 'Connectez-vous à votre espace',
		placeholderEmail: 'Courriel',
		placeholderPassword: 'Mot de passe',
		submit: 'Se connecter',
		passwordForgotten: 'Mot de passe oublié ?',
		signUp: 'Vous n\'avez pas encore activé votre compte ?',
		signUpAccount: 'Activer votre espace',
		noAccount: 'Vous n\'avez pas de compte ?',
		createAccount: 'Créer votre compte'
	},
	resetPassword: {
		title: 'Réinitialiser votre mot de passe',
		placeholderEmail: 'Courriel',
		reset: 'Réinitialiser',
		backSignIn: 'Retour à la connexion',
		emailSent: {
			title: 'Lien envoyé !',
			description: 'Un courriel a été envoyé à {email} avec des instructions pour réinitialiser votre mot de passe. Vérifiez bien que le courriel saisi correspond à celui que vous avez utilisé pour la création de votre compte.'
		},
		newPassword: 'Nouveau mot de passe',
		repeatNewPassword: 'Répétez le mot de passe'
	},
	activateAccount: {
		title: 'Activer votre Espace Client',
		confirmEmail: 'Confirmation courriel',
		activationCode: 'Code d\'activation',
		activate: 'Activer votre compte',
        helpSignIn: 'Aide à la connexion',
		emailSent: {
			title: 'Email de vérification envoyé',
			description: 'Pour finaliser la création de votre compte, veuillez consulter le message envoyé à votre adresse courriel. En activant votre compte, vous acceptez les conditions générales d\'utilisation de l\'Espace Client, accessibles depuis le courriel de confirmation.'
		},
        popinHelpLogIn: {
            title: 'Aide à la connexion',
        },
	},
	finishActivation: {
		title: 'Finaliser l\'activation de votre compte',
		password: 'Mot de passe',
		confirmPassword: 'Confirmation mot de passe',
		invalidToken: 'Ce lien d’activation a expiré. Veuillez renouveler l\'opération',
		accountActivated: 'Votre compte a bien été activé',
		errorUpdate: 'Une erreur est survenue lors de la modification du mot de passe'
	},
	mfa: {
		title: 'Vérifier avec l\'authentification par courriel',
		description: 'Envoyer un code de vérification à {email}',
		sendCode: 'Envoyer le code',
		disconnect: 'Se déconnecter',
		resendCode: 'Vous n\'avez pas encore reçu votre code?',
		sendAgain: 'Envoyer de nouveau',
		saveChoice: 'Se souvenir de mon choix sur cet appareil',
		verificationCode: 'Code de vérification',
		verify: 'Vérifier',
		checkEmail: 'Un code de vérification a été envoyé à {email}. Vérifiez vos emails et entrez le code ci-dessous.'
	},
	createAccount: {
		title: 'Créez votre compte YouFirst',
		civility: 'Civilité',
		firstname: 'Prénom',
		lastname: 'Nom',
		email: 'Courriel',
		phone: 'Téléphone mobile',
		createBtn: 'Créer votre compte',
		miss: 'Mme',
		mister: 'M',
		seeRGPD: 'Traitement des données personnelles',
		emailSent: {
			title: 'Courriel envoyé !',
			description: 'Un courriel a été envoyé à {email} avec des instructions pour finaliser la création de votre compte. Vérifiez bien que le courriel saisi est exact.'
		},
		residence: {
			prospectType: {
				contact: 'Particulier',
				account: 'Professionnel'
			},
			socialReason: 'Raison sociale',
			phone: 'Téléphone fixe'
		}
	},
	errors: {
		required: 'Requis*',
		formatPassword: 'Le mot de passe doit comporter au moins 8 caractères dont au moins un chiffre, une lettre minuscule et une lettre majuscule',
		samePassword: 'Les nouveaux mots de passe doivent correspondre',
		formatEmail: 'Format du courriel incorrect',
		notSameEmail: 'Le courriel saisi et sa confirmation sont différents',
		loginFailed: 'Votre identifiant ou votre mot de passe est incorrect. Veuillez réessayer',
		activationCodeKO: 'Votre code d\'activation ne correspond pas à nos enregistrements. Veuillez réessayer',
		verificationCodeKO: 'Votre code d\'accès ne correspond pas à nos enregistrements. Veuillez réessayer',
		emailActivationKO: 'Un utilisateur comportant ce courriel existe déjà',
		newPwdKO: 'Votre nouveau mot de passe ne doit pas être le même que votre mot de passe actuel et ne doit pas être un des anciens mots de passe utilisés',
		creationEmailCodeKO: 'Une erreur est survenue, veuillez vous rapprocher de votre interlocuteur YouFirst ou réessayer plus tard.',
		alreadyActive: 'Votre compte est déjà activé, veuillez utiliser la fonctionnalité mot de passe oublié au besoin',
		oktaDefaultMessage: 'Une erreur s\'est produite lors de l\'activation de votre compte, veuillez réessayer ultérieurement',
		creationNonAuthorized: 'Une erreur est survenue lors de la création du compte. \nVeuillez vérifier votre adresse email',
		temporaryEmail: 'Veuillez ne pas utiliser une adresse mail temporaire pour votre compte YouFirst',
		fullInbox: 'Le courriel indiqué ne peut recevoir de nouveau message',
		emailNotFound: 'Le courriel indiqué n\'a pas été trouvé pour ce domaine',
		invalidPhone: 'Le numéro de téléphone n\'est pas valide',
		inactiveUser: 'Votre compte n\'est plus actif'
	},
	formulairePartenaire: {
		titre: 'Complétez vos informations et activez votre compte YouFirst afin de prendre votre rendez-vous de visite.'
	}
}

export default flatten<typeof target, ModelTranslations>(target)
