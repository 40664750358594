import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './containers/App'
import * as serviceWorker from './serviceWorker'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import LangProvider from './components/langContext/langContext'
import { Provider } from 'react-intl-redux'
import './font/montserrat/montserrat.css'
import './font/blackerdisplay/blackerdisplay.css'
import { setupOkta } from './utils/authClient'
import rootReducer from './modules/reducers'
import { CookiesProvider } from 'react-cookie'
import domain from './enums/domainEnum'
import ReactGA from 'react-ga'
import { trackingEnum } from './modules/gtm/enum/trackingEnum'

interface OktaModel {
	client_id: string,
	redirect_uri_login: string,
	issuer: string,
	residence_uri: string,
	campus_uri: string,
	acquisition_uri: string,
	cookie_domain: string,
	login_uri: string,
	acquisition_residence_uri: string
}

const reactInit = () => {
	ReactGA.initialize(
		[
			{
				trackingId: trackingEnum.historical.id,
				gaOptions: { name: trackingEnum.historical.name }
			},
			{
				trackingId: trackingEnum.ecosystem.id,
				gaOptions: { name: trackingEnum.ecosystem.name }
			}
		]
	)

	// Create a history of your choosing (we're using a browser history in this case)

	// Gestion du basename pour aquisition
	const history = createBrowserHistory({
		basename: window.location.pathname.includes(domain.acquisition_residence) ? domain.acquisition_residence : window.location.pathname.includes(domain.acquisition) ? domain.acquisition : domain.default
	})

	// Initialisation de tous les enhancers utilisés avec Redux
	const enhancers = [
		applyMiddleware(
			thunk,
			routerMiddleware(history)
		)
	]

	// En mode dév, on utilise le devtools
	// if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
	// 	enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
	// }

	// Création du store de redux
	let store = createStore(
		rootReducer,
		compose(...enhancers)
	)

	ReactDOM.render(
		<React.StrictMode>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<LangProvider>
						<CookiesProvider>
							<App />
						</CookiesProvider>
					</LangProvider>
				</ConnectedRouter>
			</Provider>
		</React.StrictMode>,
		document.getElementById('root')
	)
}

const oktaConfig = () => {
	fetch('/api/conf/okta')
		.then(
			response => {
				if (response.ok) {
					response.json()
						.then(
							(data: OktaModel) => {
								setupOkta(data.client_id, data.redirect_uri_login, data.issuer)

								localStorage.setItem('commonUri',
									JSON.stringify(
										{
											residence_uri: data.residence_uri,
											campus_uri: data.campus_uri,
											acquisition_uri: data.acquisition_uri,
											cookie_domain: data.cookie_domain,
											login_uri: data.login_uri,
											acquisition_residence_uri: data.acquisition_residence_uri
										}
									)
								)

								reactInit()
							}
						)
				} else {
					setTimeout(
						() => {
							oktaConfig()
						}, 1000
					)
				}
			}
		)
		.catch(
			() => {
				setTimeout(
					() => {
						oktaConfig()
					}, 1000
				)
			}
		)
}

oktaConfig()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
