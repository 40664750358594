import { AccessibilityModel } from '../../../types/accessibility/accessibilityModel'
import { AccessibilityTypes } from './types'
import { SAVE_ACCESSIBILITY } from './actionTypes'

const initialState: AccessibilityModel = {
	isVisualHandicap: false
}

const accessibility = (
	state: AccessibilityModel = initialState,
	action: AccessibilityTypes
): AccessibilityModel => {
	switch (action.type) {
		case SAVE_ACCESSIBILITY: {
			return 'payload' in action ? action.payload : initialState
		}
		default:
			return state
	}
}

export default accessibility