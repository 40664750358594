import { TrackingModel } from '../types/trackingModel'

export const trackingEnum: TrackingModel = {
	historical: {
		id: 'UA-34155410-1',
		name: 'UA_Analytics_Historique'
	},
	ecosystem: {
		id: 'UA-129572197-2',
		name: 'UA_Ecosysteme_YouFirst'
	}
}