enum activateStatusEnum {
	OK = 'OK',
	activationCodeUnexist = 'UNEXIST_ACTIVATION_CODE',
	activationCodeKO = 'WRONG_ACTIVATION_CODE',
	emailActivationKO = 'EMAIL_ALREADY_EXIST',
	alreadyActive = 'ALREADY_ACTIVE',
	activationCodeKOAndAlreadyActive = 'WRONG_ACTIVATION_CODE_AND_ALREADY_ACTIVE',
	formatEmail = 'errors.dqe.format_email',
	creationNonAuthorized = 'errors.dqe.creation_non_authorized',
	temporaryEmail = 'errors.dqe.temporary_email',
	fullInbox = 'errors.dqe.full_inbox',
	emailNotFound = 'errors.dqe.email_not_found',
	invalidPhone = 'errors.dqe.invalid_phone'
}

export default activateStatusEnum
