import React, { Dispatch } from 'react'
import MuiSnackbar from '@material-ui/core/Snackbar'
import { compose } from "recompose"
import { connect } from 'react-redux'
import { hideSnackbar } from '../../modules/reducers/snackBar/actions'
import { makeStyles } from '@material-ui/core/styles'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import { SnackbarTypes } from '../../modules/reducers/snackBar/types'

const useStyles = makeStyles(theme => ({
	snackbarStyle: {
		width: "50%",
		wordBreak: "break-word"
	},
	verticalBottom: {
		verticalAlign: "bottom",
		marginRight: "20px"
	},
	text4: {
		fontSize: "12px",
		fontWeight: 600,
		fontFamily: '\'Montserrat\', sans-serif',
		lineHeight: "1.67",
	},
	colorWhite: {
		color: "white"
	}
}))

interface ModelSnackBarData {
	id: string,
	message: string,
	open: boolean,
	hideIcon?: boolean
}

interface StoreProps {
	snackBarData: ModelSnackBarData,
	hideSnackbar: () => void
}

type SnackBarProps = StoreProps
const SnackBar = (
	{
		snackBarData,
		hideSnackbar,
	}: SnackBarProps) => {

	const vertical = "bottom"
	const horizontal = "center"
	const classes = useStyles()

	const handleClose = () => {
		hideSnackbar()
	}

	return (
		<>
			<MuiSnackbar
				className={classes.snackbarStyle}
				anchorOrigin={{ vertical, horizontal }}
				key={snackBarData.id}
				open={snackBarData.open}
				onClose={handleClose}
				ContentProps={{
					'aria-describedby': snackBarData.id
				}}
				message={
					<span id={snackBarData.id}>
						{!snackBarData.hideIcon && <DoneOutlineIcon className={classes.verticalBottom} />}
						<span
							className={
								[
									classes.text4,
									classes.colorWhite
								].join(" ")
							}
						>
							{snackBarData.message}
						</span>
					</span>
				}
			/>
		</>
	)
}

const mapStateToProps = (state: any) => {
	return {
		snackBarData: state.snackBar
	}
}

const mapDispatchToProps = (dispatch: Dispatch<SnackbarTypes>) => {
	return {
		hideSnackbar: () => {
			dispatch(hideSnackbar())
		}
	}
}

export default compose<SnackBarProps, {}>(
	connect(mapStateToProps, mapDispatchToProps),
)(SnackBar)