import React, { useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { activateAccountModel, registerPostModel } from '../../types/logInModel'
import { isEmailOk } from '../../utils/formUtils'
import { Field, Form } from 'react-final-form'
import FormTextField from '../../components/fields/FormTextField'
import Button from '../../components/button/Button'
import Link from '../../components/link/Link'
import links from '../../enums/linksEnum'
import { configEnum, types } from '../../enums/fetchFactoryEnum'
import { useDispatch } from 'react-redux'
import ErrorCard from '../../components/errorCard/ErrorCard'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import useTheme from '@material-ui/core/styles/useTheme'
import errorCatcher from '../../utils/errorCatcher'
import activateStatusEnum from '../../enums/activateStatusEnum'
import { useHistory } from 'react-router'
import Loader from '../../components/loader/Loader'
import PopinHelpLogin from './PopinHelpLogin'
import { displayModal } from '../../components/dialog/reducer/actions'
import { modalTypes } from '../../components/dialog/enum/modalTypes'
import { InfoPartenaireModel } from './types/infoPartenaireModel'
import { civiliteEnum, civiliteStringEnum } from './enums/civiliteEnum'
import CreateAccountPage from '../createAccount/CreateAccountPage'
import fetchFactory from '../../utils/fetchFactory'

const FR = 'fr-FR'
const EN = 'en-US'
const EN_LANGUAGE = 'en'

const useStyle = makeStyles((theme) => ({
	...styles(theme),
	titleStyle: {
		padding: '15px 0',
		display: 'flex',
		justifyContent: 'center'
	},
	fieldStyle: {
		paddingBottom: 15
	},
	linkStyle: {
		padding: '20px 0 0'
	},
	descriptionStyle: {
		paddingTop: 15
	},
	emailSentContainer: {
		textAlign: 'center'
	}
}))

const ActivateAccountPage: React.FC = () => {
	const classes = useStyle()
	const intl = useIntl()
	const theme = useTheme()
	const history = useHistory()
	const dispatch = useDispatch()
	const [emailSent, setEmailSent] = React.useState<boolean>(false)
	const [incorrectCode, setIncorrectCode] = React.useState<boolean>(false)
	const [errorMessage, setErrorMessage] = React.useState<string>('')
	const [loading, setLoading] = React.useState(false)

	// Variables pour la création d'un compte partenaire
	// Récupération des query params
	const searchParams = useMemo(() => new URLSearchParams(history.location.search), [history.location.search])
	const [infoPartenaire, setInfoPartenaire] = React.useState<InfoPartenaireModel | null>(null)

	const titleClass: ClassValue = classNames(
		'col-xs-12',
		!emailSent && classes.titleStyle,
		classes.heavy,
		classes.title4,
		classes.colorBlue
	)

	const containerErrorClass: ClassValue = classNames(
		'col-xs-12',
		classes.fieldStyle
	)

	const descriptionClass: ClassValue = classNames(
		'col-xs-12',
		classes.colorBlue,
		classes.text2,
		classes.descriptionStyle
	)

	useEffect(
		() => {
			// Récupération du params "token" s'il existe
			if (searchParams.get('token')) {
				const token = searchParams.get('token') as string
				if (token.length > 0) {
					// Chargement de l'utilisateur en token
					setLoading(true)

					fetchFactory(`/api/dynamics/existenceComptePartenaire?token=${token}`, {}, types.json, {}, true)
						.then((typePartenaire: any) => setInfoPartenaire(typePartenaire))
						// S'il y a eu une erreur, on renvoie sur le login
						// Pas besoin de message car l'erreur peut être car l'utilisateur n'existe pas dans le CRM
						.catch(() => history.push(links.login))
						.finally(() => setLoading(false))
				} else {
					// S'il n'y a pas de token, on redirige vers la connexion
					history.push(links.login)
				}
			}
		}, [history, searchParams]
	)

	const onSubmit = (values: activateAccountModel) => {
		setLoading(true)
		const dataRegister: registerPostModel = {
			email: values.email,
			activation_code: values.activationCode,
			password: values.password,
			locale: window.navigator.language.startsWith(EN_LANGUAGE) ? EN : FR
		}

		fetch('/api/okta/register',
			{
				body: JSON.stringify(dataRegister),
				method: configEnum.post
			})
			.then(
				(response: Response) => response.json()
					.then(
						(registerResponse: any) => {
							switch (registerResponse) {
								case activateStatusEnum.OK:
									setEmailSent(true)
									break
								case activateStatusEnum.activationCodeUnexist:
									setIncorrectCode(true)
									setErrorMessage(
										`${intl.formatMessage(
											{
												id: 'errors.activationCodeKO',
												defaultMessage: 'Votre code d\'activation ne correspond pas à nos enregistrements. Veuillez réessayer',
												description: 'Activation code failed message'
											}
										)} (CODE 01)`
									)
									break
								case activateStatusEnum.activationCodeKO:
									setIncorrectCode(true)
									setErrorMessage(
										`${intl.formatMessage(
											{
												id: 'errors.activationCodeKO',
												defaultMessage: 'Votre code d\'activation ne correspond pas à nos enregistrements. Veuillez réessayer',
												description: 'Activation code failed message'
											}
										)} (CODE 02)`
									)
									break

								case activateStatusEnum.alreadyActive:
									setIncorrectCode(true)
									setErrorMessage(
										`${intl.formatMessage(
											{
												id: 'errors.alreadyActive',
												defaultMessage: 'Votre compte est déjà activé, veuillez utiliser la fonctionnalité mot de passe oublié au besoin',
												description: 'Email already exist message'
											}
										)} (CODE 03)`
									)
									break
								case activateStatusEnum.activationCodeKOAndAlreadyActive:
									setIncorrectCode(true)
									setErrorMessage(
										`${intl.formatMessage(
											{
												id: 'errors.emailActivationKO',
												defaultMessage: 'Un utilisateur comportant ce courriel existe déjà',
												description: 'Email already exist message'
											}
										)} (CODE 04)`
									)
									break
								case activateStatusEnum.formatEmail:
									setIncorrectCode(true)
									setErrorMessage(
										intl.formatMessage(
											{
												id: 'errors.formatEmail',
												defaultMessage: 'Format du courriel incorrect',
												description: 'Email format incorrect'
											}
										)
									)
									break
								case activateStatusEnum.creationNonAuthorized:
									setIncorrectCode(true)
									setErrorMessage(
										intl.formatMessage(
											{
												id: 'errors.creationNonAuthorized',
												defaultMessage: 'Création de compte non autorisée pour ce courriel',
												description: 'Email creation unauthorized'
											}
										)
									)
									break
								case activateStatusEnum.temporaryEmail:
									setIncorrectCode(true)
									setErrorMessage(
										intl.formatMessage(
											{
												id: 'errors.temporaryEmail',
												defaultMessage: 'Veuillez ne pas utiliser une adresse mail temporaire pour votre compte YouFirst',
												description: 'Temporary email'
											}
										)
									)
									break
								case activateStatusEnum.fullInbox:
									setIncorrectCode(true)
									setErrorMessage(
										intl.formatMessage(
											{
												id: 'errors.fullInbox',
												defaultMessage: 'Le courriel indiqué ne peut recevoir de nouveau message',
												description: 'Inbox is full'
											}
										)
									)
									break
								case activateStatusEnum.emailNotFound:
									setIncorrectCode(true)
									setErrorMessage(
										intl.formatMessage(
											{
												id: 'errors.emailNotFound',
												defaultMessage: 'Le courriel indiqué n\'a pas été trouvé pour ce domaine',
												description: 'Email not found in domain'
											}
										)
									)
									break
								default:
									setIncorrectCode(true)
									setErrorMessage(
										`${intl.formatMessage(
											{
												id: 'errors.oktaDefaultMessage',
												defaultMessage: 'Une erreur s\'est produite lors de l\'activation de votre compte, veuillez réessayer ultérieurement',
												description: 'Okta failed message'
											}
										)} ${registerResponse}`
									)
							}
						}
					)
			)
			.catch(
				(error) => {
					dispatch(
						errorCatcher(error, 'register')
					)
					setIncorrectCode(true)
					setErrorMessage(
						intl.formatMessage(
							{
								id: 'errors.oktaDefaultMessage',
								defaultMessage: 'Une erreur s\'est produite lors de l\'activation de votre compte, veuillez réessayer ultérieurement',
								description: 'Okta failed message'
							}
						)
					)
				}
			).finally(() => setLoading(false))
	}

	const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
		event.preventDefault()
		return false
	}

	const validate = (
		values: activateAccountModel
	) => {
		const errors: any = {}

		//Validate email and confirmEmail
		if (!values.email) {
			errors.email = intl.formatMessage(
				{
					id: 'errors.required',
					defaultMessage: 'Requis*',
					description: 'Required message'
				}
			)
		} else if (!isEmailOk(values.email)) {
			errors.email = intl.formatMessage(
				{
					id: 'errors.formatEmail',
					defaultMessage: 'Format du courriel incorrect',
					description: 'Format email error'
				}
			)
		}

		if (!values.confirmEmail) {
			errors.confirmEmail = intl.formatMessage(
				{
					id: 'errors.required',
					defaultMessage: 'Requis*',
					description: 'Required message'
				}
			)
		}

		if (values.email && values.confirmEmail && values.confirmEmail !== values.email) {
			errors.confirmEmail = intl.formatMessage(
				{
					id: 'errors.notSameEmail',
					defaultMessage: 'Le courriel saisi et sa confirmation sont différents',
					description: 'Not same email error'
				}
			)
		}

		//Validate Activation Code
		if (!values.activationCode) {
			errors.activationCode = intl.formatMessage(
				{
					id: 'errors.required',
					defaultMessage: 'Requis*',
					description: 'Required message'
				}
			)
		}
		return errors
	}

	if (loading) {
		return <Loader />
	}

	// S'il y a un type de partenaire, alors on affiche un formulaire spécifique
	if (infoPartenaire) {
		let civilite = undefined

		if (infoPartenaire?.eft_civilite === civiliteEnum.homme) {
			civilite = civiliteStringEnum.homme
		} else if (infoPartenaire.eft_civilite === civiliteEnum.femme) {
			civilite = civiliteStringEnum.femme
		}

		return (
			<CreateAccountPage
				initialValues={{
					...infoPartenaire,
					socialReason: infoPartenaire.lastname,
					email: infoPartenaire.emailaddress1,
					mobile: infoPartenaire.eft_telephonemobile2,
					acceptNews: false,
					civility: civilite
				}}
			/>
		)
	}

	// Sinon affichage du formulaire de base
	return (
		<>
			{
				emailSent ?
					<div className={classes.emailSentContainer}>
						<CheckCircleIcon style={{ color: theme.palette.blue.main }} />

						<div className={titleClass}>
							<FormattedMessage
								id="activateAccount.emailSent.title"
								defaultMessage="Email de vérification envoyé"
								description="Email sent title Activate Account title"
							/>
						</div>

						<div className={descriptionClass}>
							<FormattedMessage
								id="activateAccount.emailSent.description"
								defaultMessage="Pour finaliser la création de votre compte, veuillez consulter le message envoyé à votre adresse courriel. En activant votre compte, vous acceptez les conditions générales d'utilisation de l'Espace Client, accessibles depuis le courriel de confirmation."
								description="Email sent description Activate Account title"
							/>
						</div>
					</div>
					:
					<>
						<div className={titleClass}>
							<FormattedMessage
								id="activateAccount.title"
								defaultMessage="Activez votre Espace Client"
								description="Activate Account title"
							/>
						</div>

						{
							incorrectCode &&
							<div className={containerErrorClass}>
								<ErrorCard message={errorMessage} />
							</div>
						}

						<Form
							onSubmit={onSubmit}
							validate={validate}
							render={
								(
									{
										handleSubmit
									}
								) => (

									<form
										onSubmit={handleSubmit}
									>
										<div>
											<div className="col-xs-12">
												<div className={classes.fieldStyle}>
													<Field
														name="email"
														component={FormTextField}
														placeholder={{
															id: 'authentification.placeholderEmail',
															defaultMessage: 'Courriel',
															description: 'Email placeholder'
														}}
													/>
												</div>
												<div className={classes.fieldStyle}>
													<Field
														name="confirmEmail"
														component={FormTextField}
														placeholder={{
															id: 'activateAccount.confirmEmail',
															defaultMessage: 'Confirmation courriel',
															description: 'Confirm Email placeholder'
														}}
														inputOnPaste={onPaste}
													/>
												</div>
												<div className={classes.fieldStyle}>
													<Field
														name="activationCode"
														component={FormTextField}
														placeholder={{
															id: 'activateAccount.activationCode',
															defaultMessage: 'Code d\'activation',
															description: 'Activation Code placeholder'
														}}
													/>
												</div>
												<Button
													background="backgroundBlue"
													color="colorWhite"
													textStyle="text8"
													type="submit"
													size="mediumButton"
												>
													<FormattedMessage
														id="activateAccount.activate"
														defaultMessage="Activer votre compte"
														description="Activate account button"
													/>
												</Button>
											</div>
										</div>
									</form>

								)}
						/>
					</>
			}

			{
				!emailSent && (
					<div className="col-xs-12">
						<div className={classes.linkStyle}>
							<Link
								textStyle="text8"
								color="colorBlue"
								onClick={() => history.push(links.login)}
							>
								<FormattedMessage
									id="resetPassword.backSignIn"
									defaultMessage="Retour à la connexion"
									description="Back to log in link"
								/>
							</Link>
						</div>
					</div>
				)
			}

			<div className="col-xs-12">
				<div className={classes.linkStyle}>
					<Link
						textStyle="text8"
						color="colorBlue"
						onClick={() => dispatch(displayModal(modalTypes.helpModal))}
					>
						<FormattedMessage
							id="activateAccount.helpSignIn"
							defaultMessage="Aide à la connexion"
							description="help to log in link"
						/>
					</Link>
				</div>
			</div>
			<PopinHelpLogin />
		</>
	)
}

export default ActivateAccountPage
