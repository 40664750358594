import ReactGA from 'react-ga'
import { GtmModel } from '../types/gtmModel'
import { trackingEnum } from '../enum/trackingEnum'

export const saveInteractions = (gtmDetails: GtmModel) => ReactGA.event(
	{
		category: gtmDetails.category,
		action: gtmDetails.action,
		label: gtmDetails.label,
		nonInteraction: true
	},
	[trackingEnum.historical.name, trackingEnum.ecosystem.name]
)