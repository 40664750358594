import { modalTypes } from '../enum/modalTypes'
import { ModalModel } from '../types/types'
import {RootState} from "../../../modules/reducers";

const getLocalState = (state: RootState) => state.modal

export const getModalByType = (state: RootState, modalType: modalTypes): ModalModel => (
	getLocalState(state)[modalType]
)

export const isModalVisible = (state: RootState, modalType: modalTypes): boolean => (
	getModalByType(state, modalType) && getModalByType(state, modalType).display
)

export const getModalContent = (state: RootState, modalType: modalTypes): any => (
	getModalByType(state, modalType) && getModalByType(state, modalType).content
)
