enum localStorageEnum {
	user = 'user',
	oktaToken = 'okta-token-storage',
	residences = 'residences',
	buildings = 'buildings',
	commonUri = 'commonUri',
	language = 'language',
	accessToken = 'accessToken',
	space = 'space'
}

export default localStorageEnum
