import React from 'react'
import { Field } from 'react-final-form'
import FormRadioGroup from '../../../components/fields/FormRadioGroup'
import FormTextField from '../../../components/fields/FormTextField'
import FormPhoneField from '../../../components/fields/FormPhoneField'
import Button from '../../../components/button/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core'
import styles from '../../../styles'
import { useMediaQuery } from 'react-responsive'
import links from '../../../enums/linksEnum'
import { useLocation } from 'react-router'

const useStyles = makeStyles((theme) => ({
	...styles(theme),
	fieldStyle: {
		paddingBottom: 15
	}
}))

const CreateAccountCampusForm = () => {
	const classes = useStyles()
	const intl = useIntl()
	const location = useLocation()
	const isLargeScreen = useMediaQuery(
		{
			query: '(min-width: 1025px)'
		}
	)

	const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
		event.preventDefault()
		return false
	}

	return (
		<div className="col-xs-12">
			<div className={classes.fieldStyle}>
				<Field
					name="civility"
					component={FormRadioGroup}
					items={[
						{
							label: intl.formatMessage({
								id: 'createAccount.miss',
								defaultMessage: 'Mme',
								description: 'civility text radio'
							}),
							value: 'Mme'
						},
						{
							label: intl.formatMessage({
								id: 'createAccount.mister',
								defaultMessage: 'M',
								description: 'civility text radio'
							}),
							value: 'M'
						}
					]}
					textStyle={isLargeScreen ? 'text1' : 'text2'}
					textColor="colorBlue"
					textWeight="medium"
					margin={false}
					type="radio"
					rowDirection
					spaceBetween={false}
				/>
			</div>
			<div className={classes.fieldStyle}>
				<Field
					name="firstname"
					component={FormTextField}
					placeholder={{
						id: 'createAccount.firstname',
						defaultMessage: 'Prénom',
						description: 'Firstname placeholder'
					}}
					inputOnPaste={onPaste}
				/>
			</div>
			<div className={classes.fieldStyle}>
				<Field
					name="lastname"
					component={FormTextField}
					placeholder={{
						id: 'createAccount.lastname',
						defaultMessage: 'Nom',
						description: 'Lastname placeholder'
					}}
					inputOnPaste={onPaste}
				/>
			</div>
			<div className={classes.fieldStyle}>
				<Field
					name="email"
					component={FormTextField}
					placeholder={{
						id: 'createAccount.email',
						defaultMessage: 'Courriel',
						description: 'Email placeholder'
					}}
				/>
			</div>
			<div className={classes.fieldStyle}>
				<Field
					name="mobile"
					component={FormPhoneField}
					placeholder={intl.formatMessage({
						id: 'createAccount.phone',
						defaultMessage: 'Téléphone mobile',
						description: 'Phone placeholder'
					})}
				/>
			</div>

			<Button
				background="backgroundBlue"
				color="colorWhite"
				textStyle="text8"
				type="submit"
				size="mediumButton"
			>
				{
					location.pathname === links.activateAccount ? (
						<FormattedMessage
							id="activateAccount.activate"
							defaultMessage="Activer votre compte"
							description="Activate account button"
						/>
					) : (
						<FormattedMessage
							id="createAccount.createBtn"
							defaultMessage="Créez votre compte"
							description="Create account button"
						/>
					)
				}
			</Button>
		</div>
	)
}

export default CreateAccountCampusForm
