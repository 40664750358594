import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import youfirstEnvEnum from '../../enums/youfirstEnvEnum'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'

const useStyle = makeStyles((theme) => ({
	...styles(theme),
	loginBackground: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		height: 'fit-content',
		minHeight: '100vh',
		width: 'fit-content',
		minWidth: '100vw',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat'
	},
	defaultBackground: {
		backgroundImage: 'url(\'/img/visuel_connexion.jpg\')',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat'
	},
	acquisitionBackground: {
		backgroundImage: 'url(\'/img/LoginPicture.jpg\')'
	},
	acquisitionResiBackground: {
		backgroundImage: 'url(\'/img/visuel_connexion.jpg\')'
	},
	formContainer: {
		padding: '25px 42px'
	},
	logInContainer: {
		backgroundColor: 'white',
		border: `1px solid ${theme.palette.blue['20']}`,
		boxShadow: '0 2px 0 rgba(175, 175, 175, 0.12)',
		width: '100%',
		maxWidth: 400
	},
	logoContainer: {
		padding: '30px 90px',
		borderBottom: `1px solid ${theme.palette.blue['20']}`
	},
	logoYF: {
		width: '100%',
		maxWidth: 300
	}
}))

const LoginBackground: React.FC = (
	{
		children
	}
) => {
	const classes = useStyle()
	const isAcquisitionResi = window.location.pathname.includes(youfirstEnvEnum.ACQUISITION_RESIDENCE)

	const backgroundLoginClass: ClassValue = classNames(
		classes.loginBackground,
		isAcquisitionResi ? classes.defaultBackground : classes.acquisitionBackground
	)

	return (
		<div className={backgroundLoginClass}>
			<div className={classes.logInContainer}>
				<div className={classes.logoContainer}>
					<img className={classes.logoYF} src="https://op2static.oktacdn.com/fs/bco/4/fs0qadag17FsHku9y0x6" alt="logo yf" />
				</div>
				<div className={classes.formContainer}>
					{children}
				</div>
			</div>
		</div>
	)
}

export default LoginBackground
