import React, {useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../styles'
import {Grid} from '@material-ui/core'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import Dialog from '../../components/dialog/Dialog'
import {hideModal} from '../../components/dialog/reducer/actions'
import {modalTypes} from '../../components/dialog/enum/modalTypes'
import {useDispatch, useSelector} from 'react-redux'
import {isModalVisible} from '../../components/dialog/reducer/selector'
import {FormattedMessage, useIntl} from 'react-intl'
import CloseIcon from '@material-ui/icons/Close'
import parse from 'html-react-parser'
import {RootState} from "../reducers";
import {useMediaQuery} from "react-responsive";
import {getInformation} from "../../api/getInformation";

const useStyles = makeStyles((theme) => ({
    ...styles(theme),
    iconStyle: {
        '&:hover': {
            background: theme.palette.blue['5']
        },
        position: 'absolute',
        top: 15,
        right: 12,
        borderRadius: '100%',
        color: theme.palette.blue.main,
        cursor: 'pointer'
    },
    dialogContainerLargeScreen: {
        padding: 35
    },
    dialogContainerSmallScreen: {
        padding: 10
    },
    footerContainerStyle: {
        marginTop: 30
    },
    buttonContainerStyle: {
        alignItems: 'center'
    },
    buttonSmallStyle: {
        flexDirection: 'column-reverse',
        alignContent: 'center'
    },
    link: {
        marginTop: 10
    },
    message: {
        marginTop: 30
    }
}))

const PopinHelpLogin = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const intl = useIntl()
    const isLargeScreen = useMediaQuery(
        {
            query: '(min-width: 1025px)'
        }
    )
    const helpModalIsOpen = useSelector((state: RootState) => isModalVisible(state, modalTypes.helpModal))
    const [helpPopInMessage, sethelpPopInMessage] = React.useState<string>('')

    const dialogClass: ClassValue = classNames(
        isLargeScreen ? classes.dialogContainerLargeScreen : classes.dialogContainerSmallScreen
    )

    const dialogTitle: ClassValue = classNames(
        classes.colorAccessibility,
        classes.title2
    )

    const dialogText: ClassValue = classNames(
        classes.message,
        classes.text1
    )

    useEffect(() => {
        getInformation(intl.locale, 'helpLogInInformation')
            .then((data: any) => {
                sethelpPopInMessage(typeof data === 'string' ? data : data.content)
            })
    }, [intl.locale])

    const closePopIn = () => {
        dispatch(hideModal(modalTypes.helpModal))
    }

    return (
        <Dialog
            onCloseDialog={closePopIn}
            open={helpModalIsOpen}
            fullScreen={!isLargeScreen}
            maxWidth='md'
            withPadding={false}
        >
            <Grid item container className={dialogClass}>
                <Grid item xs={12} className={dialogTitle}>
                    <FormattedMessage
                        id="activateAccount.popinHelpLogIn.title"
                        defaultMessage="Aide à la connexion"
                        description="Help in title"
                    />
                    <CloseIcon
                        onClick={closePopIn}
                        className={classes.iconStyle}
                    />
                </Grid>
                <Grid item xs={12} className={dialogText}>
                    {
                        parse(helpPopInMessage ?? '')
                    }
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default PopinHelpLogin