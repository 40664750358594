import React from 'react'
import { Field } from 'react-final-form'
import FormTextField from '../../../components/fields/FormTextField'
import FormPhoneField from '../../../components/fields/FormPhoneField'
import Button from '../../../components/button/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core'
import styles from '../../../styles'

const useStyles = makeStyles((theme) => ({
	...styles(theme),
	fieldStyle: {
		paddingBottom: 15
	}
}))

const CreateAccountResidenceForm = () => {
	const classes = useStyles()
	const intl = useIntl()

	const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
		event.preventDefault()
		return false
	}

	return (
		<div className="col-xs-12">
			<div className={classes.fieldStyle}>
				<Field
					name="socialReason"
					component={FormTextField}
					placeholder={{
						id: 'createAccount.residence.socialReason',
						defaultMessage: 'Raison sociale',
						description: 'Social reason placeholder'
					}}
					inputOnPaste={onPaste}
					maxLength={255}
				/>
			</div>
			<div className={classes.fieldStyle}>
				<Field
					name="email"
					component={FormTextField}
					placeholder={{
						id: 'createAccount.email',
						defaultMessage: 'Courriel',
						description: 'Email placeholder'
					}}
				/>
			</div>
			<div className={classes.fieldStyle}>
				<Field
					name="mobile"
					component={FormPhoneField}
					placeholder={intl.formatMessage({
						id: 'createAccount.residence.phone',
						defaultMessage: 'Téléphone fixe',
						description: 'Phone placeholder'
					})}
				/>
			</div>

			<Button
				background="backgroundBlue"
				color="colorWhite"
				textStyle="text8"
				type="submit"
				size="mediumButton"
			>
				<FormattedMessage
					id="createAccount.createBtn"
					defaultMessage="Créez votre compte"
					description="Create account button"
				/>
			</Button>
		</div>
	)
}

export default CreateAccountResidenceForm
