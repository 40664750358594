import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import Link from '../../components/link/Link'
import links from '../../enums/linksEnum'
import linksEnum from '../../enums/linksEnum'
import { useHistory, useLocation } from 'react-router'
import Loader from '../../components/loader/Loader'
import ErrorCard from '../../components/errorCard/ErrorCard'
import youfirstEnvEnum from '../../enums/youfirstEnvEnum'
import CreateAccountCampusPage from './campus/CreateAccountCampusPage'
import CreateAccountResidencePage from './residence/CreateAccountResidencePage'
import { CreateResidenceFormModel } from '../../types/logInModel'

export const FR = 'fr-FR'
export const EN = 'en-US'
export const EN_LANGUAGE = 'en'


const useStyle = makeStyles((theme) => ({
	...styles(theme),
	titleStyle: {
		padding: '15px 0px',
		display: 'flex',
		justifyContent: 'center'
	},
	fieldStyle: {
		paddingBottom: 15
	},
	linkStyle: {
		padding: '25px 0px',

		'& a:last-child': {
			paddingTop: 10
		}
	},
	descriptionStyle: {
		paddingTop: 15
	},
	emailSentContainer: {
		textAlign: 'center'
	}
}))

interface AcceptingProps {
	initialValues?: CreateResidenceFormModel
}

type CreateAccountPageProps = AcceptingProps

const CreateAccountPage: React.FC<CreateAccountPageProps> = (
	{
		initialValues
	}
) => {
	const classes = useStyle()
	const intl = useIntl()
	const history = useHistory()
	const location = useLocation()
	const [emailSent, setEmailSent] = React.useState<string | undefined>(undefined)
	const [loading, setLoading] = React.useState(false)
	const [incorrectCode, setIncorrectCode] = React.useState<boolean>(false)
	const [errorMessage, setErrorMessage] = React.useState<string>('')

	const titleClass: ClassValue = classNames(
		'col-xs-12',
		classes.titleStyle,
		classes.heavy,
		classes.title4,
		classes.colorBlue
	)

	const descriptionClass: ClassValue = classNames(
		'col-xs-12',
		classes.colorBlue,
		classes.text2,
		classes.descriptionStyle
	)

	const containerErrorClass: ClassValue = classNames(
		'col-xs-12',
		classes.fieldStyle
	)

	const isResidence = () => window.location.pathname.includes(youfirstEnvEnum.ACQUISITION_RESIDENCE)

	const goToRGPD = () => {
		if (isResidence()) {
			window.open(`${JSON.parse(localStorage.getItem('commonUri') || '').acquisition_residence_uri}/${intl.locale}/footer/politique-de-protection-des-donnees-personnelles`, '_blank')
		} else {
			window.open(`${JSON.parse(localStorage.getItem('commonUri') || '').acquisition_uri}/${intl.locale}/rgpd`, '_blank')
		}
	}

	if (loading) {
		return <Loader />
	}

	return (
		<>

			{
				emailSent ?
					<div className={classes.emailSentContainer}>
						<img src="https://secure.adnxs.com/px?id=1489556&seg=26772456&t=2" width="1" height="1" alt="Tag" />
						<div className={titleClass}>
							<FormattedMessage
								id="createAccount.emailSent.title"
								defaultMessage="Courriel envoyé !"
								description="Email sent title create Account title"
							/>
						</div>

						<div className={descriptionClass}>
							<FormattedMessage
								id="createAccount.emailSent.description"
								defaultMessage="Un courriel a été envoyé à {email} avec des instructions pour finaliser la création de votre compte. Vérifiez bien que le courriel saisi est exact."
								description="Email sent description create account title"
								values={{ email: emailSent }}
							/>
						</div>
					</div>
					:
					<>
						{
							location.pathname !== linksEnum.activateAccount && (
								<div className={titleClass}>
									<FormattedMessage
										id="createAccount.title"
										defaultMessage="Créez votre compte YouFirst"
										description="Create Account title"
									/>
								</div>
							)
						}

						{
							incorrectCode &&
							<div className={containerErrorClass}>
								<ErrorCard message={errorMessage} />
							</div>
						}

						{
							isResidence() ? (
								<CreateAccountResidencePage
									initialValues={initialValues}
									setLoading={setLoading}
									setEmailSent={setEmailSent}
									setIncorrectCode={setIncorrectCode}
									setErrorMessage={setErrorMessage}
								/>
							) : (
								<CreateAccountCampusPage
									setLoading={setLoading}
									setEmailSent={setEmailSent}
									setIncorrectCode={setIncorrectCode}
									setErrorMessage={setErrorMessage}
								/>
							)
						}
					</>
			}

			{
				!emailSent && (
					<div className="col-xs-12">
						<div className={classes.linkStyle}>
							<Link
								textStyle="text8"
								color="colorBlue"
								onClick={() => history.push(links.login)}
							>
								<FormattedMessage
									id="resetPassword.backSignIn"
									defaultMessage="Retour à la connexion"
									description="Back to log in link"
								/>
							</Link>

							<Link
								color="colorBlue"
								textStyle="text2"
								onClick={goToRGPD}
							>
								<FormattedMessage
									id="createAccount.seeRGPD"
									defaultMessage="Traitement des données personnelles"
									description="RGPD link"
								/>
							</Link>
						</div>
					</div>
				)
			}
		</>
	)
}

export default CreateAccountPage
