import fetchFactory from "../utils/fetchFactory";
import types, {configEnum} from "../enums/fetchFactoryEnum";

export const getInformation = (lang: string, information_name: string) => {

    const fd = new FormData()
    fd.append('route', `/${lang}/api/content/yf_information?yf_information_name=${information_name}`)

    return fetchFactory('/api/acquisition/drupalREST',
        {
            method: configEnum.post,
            body: fd
        },
        types.json,
        null,
        true,
    )
}