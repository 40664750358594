import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import { FormattedMessage, useIntl } from 'react-intl'
import { Field, Form } from 'react-final-form'
import FormTextField from '../../components/fields/FormTextField'
import Button from '../../components/button/Button'
import Link from '../../components/link/Link'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { forgotPasswordPostModel, resetModel } from '../../types/logInModel'
import { isEmailOk } from '../../utils/formUtils'
import { configEnum } from '../../enums/fetchFactoryEnum'
import errorCatcher from '../../utils/errorCatcher'
import { useDispatch } from 'react-redux'
import statusEnum from '../../enums/statusEnum'
import links from '../../enums/linksEnum'
import { useHistory } from 'react-router'
import Loader from '../../components/loader/Loader'

const useStyle = makeStyles(theme => ({
	...styles(theme),
	titleStyle: {
		padding: '15px 0px',
		display: 'flex',
		justifyContent: 'center'
	},
	fieldStyle: {
		paddingBottom: 30
	},
	linkStyle: {
		padding: '25px 0px'
	},
	descriptionStyle: {
		paddingTop: 15
	}
}))

const ResetPassword: React.FC = () => {
	const classes = useStyle()
	const intl = useIntl()
	const dispatch = useDispatch()
	const history = useHistory()
	const [sentEmail, setSentEmail] = React.useState(false)
	const [email, setEmail] = React.useState('')
	const [loading, setLoading] = React.useState(false)

	const titleClass: ClassValue = classNames(
		'col-xs-12',
		classes.titleStyle,
		classes.heavy,
		classes.title4,
		classes.colorBlue
	)

	const descriptionClass: ClassValue = classNames(
		'col-xs-12',
		classes.colorBlue,
		classes.text3,
		classes.descriptionStyle
	)

	const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value)
	}

	const onSubmit = () => {
		setLoading(true)
		const dataForgotPwd: forgotPasswordPostModel = {
			email: email
		}
		fetch('/api/okta/forgotPassword',
			{
				body: JSON.stringify(dataForgotPwd),
				method: configEnum.post
			})
			.then(
				(response: Response) => response.json()
					.then(
						(forgotPwdResponse: any) => {
							if (forgotPwdResponse === statusEnum.OK) {
								setSentEmail(true)
							}
						}
					)
			)
			.catch(
				(error) => {
					dispatch(
						errorCatcher(error, 'forgot password')
					)
				}
			)
			.finally(()=> setLoading(false))
	}

	const validate = (
		values: resetModel
	) => {
		const errors: any = {}

		if (!values.email) {
			errors.email = intl.formatMessage(
				{
					id: 'errors.required',
					defaultMessage: 'Requis*',
					description: 'Required message'
				}
			)
		} else if (!isEmailOk(values.email)) {
			errors.email = intl.formatMessage(
				{
					id: 'errors.formatEmail',
					defaultMessage: 'Format du courriel incorrect',
					description: 'Format email error'
				}
			)
		}
		return errors
	}

	if(loading) {
		return <Loader/>
	}

	return (
		<>
			<div className={titleClass}>
				{
					sentEmail ?
						<FormattedMessage
							id="resetPassword.emailSent.title"
							defaultMessage="Lien envoyé !"
							description="Email Sent title"
						/>
						:
						<FormattedMessage
							id="resetPassword.title"
							defaultMessage="Réinitialiser votre mot de passe"
							description="Reset pwd title"
						/>
				}
			</div>

			{
				sentEmail ?
					<div className={descriptionClass}>
						<FormattedMessage
							id="resetPassword.emailSent.description"
							defaultMessage="Un courriel a été envoyé à {email} avec des instructions pour réinitialiser votre mot de passe. Vérifiez bien que le courriel saisi correspond à celui que vous avez utilisé pour la création de votre compte."
							description="Email sent description"
							values={{ email: email }}
						/>
					</div>
					:
					<Form
						onSubmit={onSubmit}
						validate={validate}
						render={
							(
								{
									handleSubmit
								}
							) => (

								<form
									onSubmit={handleSubmit}
								>
									<div>
										<div className="col-xs-12">
											<div className={classes.fieldStyle}>
												<Field
													name="email"
													component={FormTextField}
													placeholder={{
														id: 'authentification.placeholderEmail',
														defaultMessage: 'Courriel',
														description: 'Email placeholder'
													}}
													inputOnChange={onChangeEmail}
												/>
											</div>
											<Button
												background="backgroundBlue"
												color="colorWhite"
												textStyle="text8"
												type="submit"
												size="mediumButton"
											>
												<FormattedMessage
													id="resetPassword.reset"
													defaultMessage="Réinitialiser"
													description="Reset message"
												/>
											</Button>
										</div>
									</div>
								</form>

							)}
					/>
			}

			<div className="col-xs-12">
				<div className={classes.linkStyle}>
					<Link
						textStyle="text8"
						color="colorBlue"
						onClick={() => history.push(links.login)}
					>
						<FormattedMessage
							id="resetPassword.backSignIn"
							defaultMessage="Retour à la connexion"
							description="Back to log in link"
						/>
					</Link>
				</div>
			</div>
		</>
	)
}

export default ResetPassword
