import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FieldRenderProps } from 'react-final-form'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'
import styles from '../../styles'
import IntlTelInput, { CountryData } from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'

const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			textFieldStyle: {
				width: '100%',
				padding: '8px 8px 8px 0px',

				'& input': {
					...styles(theme).text1,
					fontSize: 18,
					fontWeight: 500,
					opacity: 0.7,
					lineHeight: 1.33,
					border: 'none',
					borderBottom: '2px solid #d0d4d9',
					paddingTop: 7
				}
			},
			errorStyle: {
				color: '#B92D00',
				fontSize: '10px'
			},
			errorColor: {
				color: '#B92D00 !important'
			},
			errorBorder: {
				border: '1px solid #B92D00'
			},
			disabledStyle: {
				'& input': {
					opacity: '0.7 !important'
				},
				'& .MuiInput-underline:before': {
					borderBottom: '0'
				}
			},
			textFieldContainer: {
				marginTop: 10
			}
		}
	)
)

interface AcceptingProps {
	label?: string,
	disabled?: boolean,
	placeholder?: string
}

type FormTextFieldDossierProps =
	FieldRenderProps<string>
	& AcceptingProps

const FormPhoneField: React.FC<FormTextFieldDossierProps> = (
	{
		input,
		meta: {
			touched,
			error,
			submitError
		},
		label,
		disabled,
		placeholder
	}
) => {
	const classes = useStyles()
	const inputRef = useRef(null)
	const labelClass: ClassValue = classNames(
		classes.text8,
		{
			[classes.errorColor]: touched && error
		}
	)

	const textFieldClass: ClassValue = classNames(
		'intl-tel-input',
		classes.textFieldStyle,
		{
			[classes.errorBorder]: touched && error,
			[classes.disabledStyle]: disabled
		}
	)

	/**
	 * Fonction pour replacer le curseur à la fin de l'input (le formatage du numéro déplace le curseur au milieu du champ)
	 */
	const setPositionCurseur = (positionCurseur: number) => {
		const elem = document.getElementsByClassName('phone-input')[0] as HTMLInputElement;

		if (elem) {
			elem.focus();
			setTimeout(() => {
				elem.setSelectionRange(positionCurseur, positionCurseur);
			}, 0);
		}
	}

	const onChange = (isValid: boolean, value: string, selectedCountryData: CountryData, fullNumber: string, extension: string) => {
		setPositionCurseur(fullNumber.length);
		input.onChange(fullNumber)
	}

	return (
		<div className={classes.textFieldContainer}>
			<span
				className={labelClass}
			>
				{label}
			</span>

			<IntlTelInput
				ref={inputRef}
				preferredCountries={['fr']}
				onPhoneNumberChange={onChange}
				containerClassName={textFieldClass}
				placeholder={placeholder}
				value={input.value}
				inputClassName="phone-input"
			/>

			{(error || submitError) && touched && (
				<span className={classes.errorStyle}>{error || submitError}</span>
			)}
		</div>
	)
}

export default FormPhoneField
