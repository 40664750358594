enum links {
	login = '/login',
	checkOkta = '/implicit/callback',
	cgu = '/cgu',
	checkUser = '/check/user',
	resetPwd = '/signin/password-reset',
	activateAccountEnd = '/activate',
	forgottenPwd = '/forgotten',
	activateAccount = '/activation-account',
	mfa = '/mfa',
	createAccount = '/creation-account',
}

export default links
