import { combineReducers } from 'redux'
import reducers from '../../config/reducers'
import { connectRouter } from "connected-react-router"
import { intlReducer } from 'react-intl-redux'
import { reducer as formReducer } from 'redux-form'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

const rootReducer = combineReducers({
	...reducers,
	router: connectRouter(history),
	intl: intlReducer,
	form: formReducer
})

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>