import { DISPLAY_SNACKBAR, HIDE_SNACKBAR } from './actionTypes'
import { SnackbarTypes } from './types'
import { SnackBarModel } from '../../../types/snackBarModel'

const initialState: SnackBarModel = {
	id: "",
	message: "",
	open: false
}

const snackBar = (
	state: SnackBarModel = initialState,
    action: SnackbarTypes
): SnackBarModel => {
	switch (action.type) {
		case DISPLAY_SNACKBAR: {
			return 'payload' in action ? action.payload : initialState
		}
		case HIDE_SNACKBAR: {
			return initialState
		}
		default:
			return state
	}
}

export default snackBar