import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'
import { FormattedMessageModel } from '../../types/fields/fieldsModel'
import { useIntl } from 'react-intl'
import { InputAdornment } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
import { FieldRenderProps } from 'react-final-form'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			textFieldStyle: {
				width: '100%',
				padding: '8px 8px 8px 0px',
				boxSizing: 'border-box'
			},
			textFieldLargeScreenStyle: {
				'& .MuiInput-formControl': {
					'& .MuiInput-input': {
						fontSize: 14,
						fontWeight: 500,
						color: theme.palette.blue.main,
						fontFamily: '\'Montserrat\', sans-serif'
					}
				}
			},
			textFieldSmallScreenStyle: {
				'& .MuiInput-formControl': {
					'& .MuiInput-input': {
						fontSize: 14,
						fontWeight: 500,
						color: theme.palette.blue.main,
						fontFamily: '\'Montserrat\', sans-serif'
					}
				}
			},
			errorStyle: {
				color: '#B92D00',
				fontSize: 10
			},
			errorColor: {
				color: '#B92D00 !important'
			},
			errorBorder: {
				border: '1px solid #B92D00'
			}
		}
	)
)

interface AcceptingProps {
	id: string,
	label?: string,
	placeholder?: FormattedMessageModel,
	multiline?: boolean
	maxLength?: number,
	type?: string,
	disablePaste: boolean,
	adornment?: string,
	inputOnChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
	inputOnPaste?: () => void,
	showPassword?: boolean,
	seePassword?: boolean,
	handleClickShowPassword?: () => void
}

type FormTextFieldProps =
	FieldRenderProps<string>
	& AcceptingProps

const FormTextField: React.FC<FormTextFieldProps> = (
	{
		input,
		meta: {
			touched,
			error
		},
		id,
		label,
		placeholder,
		multiline,
		maxLength,
		type = 'text',
		disablePaste = false,
		adornment,
		inputOnChange,
		inputOnPaste,
		showPassword,
		seePassword,
		handleClickShowPassword
	}
) => {
	const classes = useStyles()
	const intl = useIntl()
	const isLargeScreen = useMediaQuery(
		{
			query: '(min-width: 1025px)'
		}
	)

	const labelClass: ClassValue = classNames(
		classes.text5,
		classes.colorBlue,
		{
			[classes.errorColor]: touched && error
		}
	)

	const textFieldClass: ClassValue = classNames(
		classes.textFieldStyle,
		{
			[classes.textFieldLargeScreenStyle]: isLargeScreen,
			[classes.textFieldSmallScreenStyle]: !isLargeScreen,
			[classes.errorBorder]: touched && error
		}
	)

	// Gestion d'un éventuel adornment
	const startAdornment = adornment ? {
		startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>
	} : {}

	const handleMouseDownPassword = (event: any) => {
		event.preventDefault()
	}

	return (
		<>
			<span
				className={labelClass}
			>
				{label}
			</span>
			<TextField
				id={id}
				color="primary"
				className={textFieldClass}
				multiline={multiline}
				inputProps={{
					maxLength: maxLength,
					onPaste: inputOnPaste
				}}
				placeholder={placeholder ?
					intl.formatMessage(
						{
							id: placeholder.id,
							defaultMessage: placeholder.defaultMessage,
							description: placeholder.description
						}
					) : ''
				}
				onPaste={(e) => disablePaste && e.preventDefault()}
				InputProps={
					{
						...startAdornment,
						onChange: e => {
							input.onChange(e)
							inputOnChange && inputOnChange(e)
						},
						endAdornment: showPassword ? (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
								>
									{seePassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						) : undefined
					}
				}
				{...input}

			/>

			{
				touched &&
				((error && <span className={classes.errorStyle}>{error}</span>))
			}
		</>
	)
}

export default FormTextField
