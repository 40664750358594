const styles = (theme: any) => {
	return (
		{
			backgroundDynamicGreenToWhite:{
				backgroundColor: `${theme.palette.dynamicGreenToWhite.main} !important`
			},
			backgroundAccessibility: {
				backgroundColor: `${theme.palette.accessibility.main} !important`
			},
			backgroundGreen: {
				backgroundColor: `${theme.palette.green.main} !important`
			},
			backgroundPurple: {
				backgroundColor: `${theme.palette.purple.main} !important`
			},
			backgroundBlue: {
				backgroundColor: `${theme.palette.blue.main} !important`
			},
			backgroundBrown: {
				backgroundColor: `${theme.palette.brown.main} !important`
			},
			backgroundDynamic: {
				backgroundColor: `${theme.palette.dynamic.main} !important`
			},
			backgroundWhite: {
				backgroundColor: 'white !important'
			},
			backgroundInherit: {
				backgroundColor: 'inherit !important'
			},
			borderGreen: {
				border: `1px solid ${theme.palette.green.main}`
			},
			borderPurple: {
				border: `1px solid ${theme.palette.purple.main}`
			},
			borderBlue: {
				border: `1px solid ${theme.palette.blue.main}`
			},
			borderBrown: {
				border: `1px solid ${theme.palette.brown.main}`
			},
			borderDynamic: {
				border: `1px solid ${theme.palette.dynamic.main}`
			},
			borderWhite: {
				border: '1px solid white'
			},
			title0: theme.typography.title0,
			title1: theme.typography.title1,
			title2: theme.typography.title2,
			title3: theme.typography.title3,
			title4: theme.typography.title4,
			title5: theme.typography.title5,
			title6: theme.typography.title6,
			title7: theme.typography.title7,
			title8: theme.typography.title8,
			text0: theme.typography.text0,
			text0_opacity: theme.typography.text0_opacity,
			text1_opacity: theme.typography.text1_opacity,
			text1: theme.typography.text1,
			text2_opacity: theme.typography.text2_opacity,
			text2: theme.typography.text2,
			text3_opacity: theme.typography.text3_opacity,
			text3: theme.typography.text3,
			text4_opacity: theme.typography.text4_opacity,
			text4: theme.typography.text4,
			text5: theme.typography.text5,
			text6: theme.typography.text6,
			text6_opacity: theme.typography.text6_opacity,
			text7: theme.typography.text7,
			text8: theme.typography.text8,
			text9: theme.typography.text9,
			text9_opacity: theme.typography.text9_opacity,
			text10: theme.typography.text10,
			text11: theme.typography.text11,
			text11_opacity: theme.typography.text11_opacity,
			text12: theme.typography.text12,
			text13: theme.typography.text13,
			text13_opacity: theme.typography.text13_opacity,
			text14: theme.typography.text14,
			text15: theme.typography.text15,
			text16_opacity: theme.typography.text16_opacity,
			text17: theme.typography.text17,
			text18: theme.typography.text18,
			text19: theme.typography.text19,
			colorGreen: {
				color: theme.palette.green.main
			},
			colorPurple: {
				color: theme.palette.purple.main
			},
			colorBlue: {
				color: theme.palette.blue.main
			},
			colorBrown: {
				color: theme.palette.brown.main
			},
			colorDynamic: {
				color: theme.palette.dynamic.main
			},
			colorAccessibility: {
				color: theme.palette.accessibility.main
			},
			colorTextAccessibility: {
				color: theme.palette.textAccessibility.main
			},
			colorWhite: {
				color: 'white'
			},
			cursorPointer: {
				cursor: 'pointer'
			},
			medium: {
				fontWeight: 500
			},
			semiBold: {
				fontWeight: 600
			},
			heavy: {
				fontWeight: 900
			},
			flex: {
				display: 'flex'
			},
			flexBetween: {
				justifyContent: 'space-between'
			},
			flexEnd: {
				justifyContent: 'flex-end'
			},
			flexCenter: {
				justifyContent: 'center'
			},
			alignCenter: {
				alignItems: 'center'
			},
			flexColumn: {
				flexDirection: 'column' as 'column'
			},
			flexColumnReverse: {
				flexDirection: 'column-reverse' as 'column-reverse'
			},
			textRight: {
				textAlign: 'right' as 'right'
			}
		}
	)
}

export default styles
