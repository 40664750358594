import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../../styles'
import { FormattedMessage, useIntl } from 'react-intl'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import Link from '../../components/link/Link'
import Button from '../../components/button/Button'
import { Field, Form } from 'react-final-form'
import FormTextField from '../../components/fields/FormTextField'
import { mfaActivatePostModel, mfaCheckPostModel, mfaModel, mfaResendCodePostModel, mfaResponseModel } from '../../types/logInModel'
import CheckBoxField from '../../components/fields/checkBox/CheckBoxField'
import { configEnum } from '../../enums/fetchFactoryEnum'
import errorCatcher from '../../utils/errorCatcher'
import { useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie/cjs'
import ErrorCard from '../../components/errorCard/ErrorCard'
import { useHistory, useLocation } from 'react-router'
import links from '../../enums/linksEnum'
import Loader from '../../components/loader/Loader'

const CODE_VERIFIER: string = 'code_verifier'
const INIT_MFA_INFO = {
	email: '',
	factor_id: '',
	state_token: '',
	verify_url: '',
	status: ''
}

const useStyle = makeStyles((theme) => ({
	...styles(theme),
	titleStyle: {
		padding: '15px 0px',
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center'
	},
	linkStyle: {
		padding: '25px 0px',
		display: 'flex',
		justifyContent: 'flex-end'
	},
	descriptionStyle: {
		paddingBottom: 25
	},
	checkBoxStyle: {
		paddingBottom: 10
	},
	betweenField: {
		paddingBottom: 15
	}
}))

const MFA: React.FC = () => {
	const classes = useStyle()
	const intl = useIntl()
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()
	// @ts-ignore
	const mfaInfo: mfaResponseModel = location.state ? location.state.mfaInfo : INIT_MFA_INFO
	// @ts-ignore
	const isMfaEnroll: boolean = location.state ? location.state.isMfaEnroll : false
	const [showMessage, setShowMessage] = useState<boolean>(false)
	const setCookie = useCookies([CODE_VERIFIER])[1]
	const [incorrectCode, setIncorrectCode] = React.useState<boolean>(false)
	const [loading, setLoading] = React.useState(false)

	const titleClass: ClassValue = classNames(
		'col-xs-12',
		classes.titleStyle,
		classes.heavy,
		classes.title4,
		classes.colorBlue
	)

	const descriptionClass: ClassValue = classNames(
		classes.colorBlue,
		classes.text3,
		classes.descriptionStyle
	)

	const textClass: ClassValue = classNames(
		classes.colorBlue,
		classes.text3
	)

	const containerErrorClass: ClassValue = classNames(
		'col-xs-12',
		classes.betweenField
	)

	useEffect(() => {
		const timeout = setTimeout(() => {
			setShowMessage(true)
		}, 30000)

		return () => clearTimeout(timeout)
	}, [])

	const onSubmit = (values: mfaModel) => {
		setLoading(true)
		if (!isMfaEnroll) {
			const dataMFAChek: mfaCheckPostModel = {
				code_verification: values.verificationCode,
				factor_id: mfaInfo.factor_id,
				state_token: mfaInfo.state_token,
				rememberChoice: values.rememberChoice
			}

			fetch('/api/okta/MFA/check',
				{
					body: JSON.stringify(dataMFAChek),
					method: configEnum.post
				})
				.then(
					(response: Response) => response.json()
						.then(
							(checkMFAResponse: any) => {
								if (!checkMFAResponse.status) {
									setCookie(CODE_VERIFIER, checkMFAResponse.code_verifier, { path: '/' })
									localStorage.setItem(CODE_VERIFIER, checkMFAResponse.code_verifier)
									window.location.href = checkMFAResponse.redirect
								} else {
									setIncorrectCode(true)
								}
							}
						)
				)
				.catch(
					(error) => {
						dispatch(
							errorCatcher(error, 'check send code')
						)
					}
				).finally(()=>setLoading(false))
		} else {
			const dataMFAActivate: mfaActivatePostModel = {
				pass_code: values.verificationCode,
				factor_id: mfaInfo.factor_id,
				state_token: mfaInfo.state_token
			}
			fetch('/api/okta/MFA/activate',
				{
					body: JSON.stringify(dataMFAActivate),
					method: configEnum.post
				})
				.then(
					(response: Response) => response.json()
						.then(
							(activateMFAResponse: any) => {
								if (!activateMFAResponse.status) {
									setCookie(CODE_VERIFIER, activateMFAResponse.code_verifier, { path: '/' })
									localStorage.setItem(CODE_VERIFIER, activateMFAResponse.code_verifier)
									window.location.href = activateMFAResponse.redirect
								} else {
									setIncorrectCode(true)
								}
							}
						)
				)
				.catch(
					(error) => {
						dispatch(
							errorCatcher(error, 'activate send code')
						)
					}
				).finally(()=>setLoading(false))
		}
	}

	const validate = (
		values: mfaModel
	) => {
		const errors: any = {}

		if (!values.verificationCode) {
			errors.verificationCode = intl.formatMessage(
				{
					id: 'errors.required',
					defaultMessage: 'Requis*',
					description: 'Required message'
				}
			)
		}

		return errors
	}

	const sendCode = () => {

		const dataResendCode: mfaResendCodePostModel = {
			factor_id: mfaInfo.factor_id,
			state_token: mfaInfo.state_token
		}

		fetch('/api/okta/MFA/resend',
			{
				body: JSON.stringify(dataResendCode),
				method: configEnum.post
			})
			.catch(
				(error) => {
					dispatch(
						errorCatcher(error, 'resend code')
					)
				}
			)
	}

	const emailToSendCode = () => {
		return (
			<b>{mfaInfo.email}</b>
		)
	}

	if(loading) {
		return <Loader/>
	}

	return (
		<>
			<div className={titleClass}>
				<FormattedMessage
					id="mfa.title"
					defaultMessage="Vérifier avec l'authentification par courriel"
					description="MFA title"
				/>
			</div>

			{
				incorrectCode &&
				<div className={containerErrorClass}>
					<ErrorCard message={intl.formatMessage(
						{
							id: 'errors.verificationCodeKO',
							defaultMessage: 'Votre code d\'accès ne correspond pas à nos enregistrements. Veuillez réessayer',
							description: 'Verification code failed message'
						}
					)} />
				</div>
			}

			<div className="col-xs-12">
				<div className={descriptionClass}>
					<FormattedMessage
						id="mfa.checkEmail"
						defaultMessage="Un code de vérification a été envoyé à {email}. Vérifiez vos emails et entrez le code ci-dessous."
						description="Description check email"
						values={{ email: emailToSendCode() }}
					/>
				</div>
				<Form
					onSubmit={onSubmit}
					validate={validate}
					initialValues={{
						rememberChoice: true
					}}
					render={
						(
							{
								handleSubmit
							}
						) => (

							<form
								onSubmit={handleSubmit}
							>
								<div>

									<div>
										<Field
											name="verificationCode"
											component={FormTextField}
											placeholder={{
												id: 'mfa.verificationCode',
												defaultMessage: 'Code de vérification',
												description: 'Verification code placeholder'
											}}
										/>
									</div>

									{showMessage &&
									<div className={classes.descriptionStyle}>
										<div className={textClass}>
											<FormattedMessage
												id="mfa.resendCode"
												defaultMessage="Vous n'avez pas encore reçu votre code?"
												description="Description code not received"
											/>
										</div>
										<Link
											textStyle="text4"
											color="colorBlue"
											onClick={sendCode}
										>
											<FormattedMessage
												id="mfa.sendAgain"
												defaultMessage="Envoyer de nouveau"
												description="Send code again"
											/>
										</Link>
									</div>
									}

									{
										!isMfaEnroll && <div className={classes.checkBoxStyle}>
											<Field
												name="rememberChoice"
												component={CheckBoxField}
												label={
													intl.formatMessage(
														{
															id: 'mfa.saveChoice',
															defaultMessage: 'Se souvenir de mon choix sur cet appareil',
															description: 'Accept cgu description check box'
														})
												}
												color="colorBlue"
												textStyle="text3"
												type="checkbox"
											/>
										</div>
									}

									<Button
										background="backgroundBlue"
										color="colorWhite"
										textStyle="text8"
										type="submit"
										size="mediumButton"
									>
										<FormattedMessage
											id="mfa.verify"
											defaultMessage="Vérifier"
											description="Verify message"
										/>
									</Button>
								</div>
							</form>

						)}
				/>
			</div>

			<div className={classes.linkStyle}>
				<Link
					textStyle="text8"
					color="colorBlue"
					onClick={() => history.push(links.login)}
				>
					<FormattedMessage
						id="mfa.disconnect"
						defaultMessage="Se déconnecter"
						description="Back to log in link"
					/>
				</Link>
			</div>
		</>
	)
}

export default MFA
