import * as OktaAuth from '@okta/okta-auth-js'

const config = {
	pkce:  true,
	storage: 'localStorage',
	tokenManager: {
		autoRenew: true,
		expireEarlySeconds: 10
	}
}

let authClient: any;

export const setupOkta = (client_id: string, redirect_uri: string, issuer: string) => {
	authClient = new OktaAuth(
		{
			...config,
			clientId: client_id,
			redirectUri: redirect_uri,
			issuer: issuer
		}
	)
}

export const getAuthClient = () => {
	return authClient
}