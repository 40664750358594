import React from 'react'
import { FormControlLabel, withStyles } from '@material-ui/core'
import MuiCheckbox from '@material-ui/core/Checkbox'
import { FieldRenderProps } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			textFieldStyle: {
				width: '100%',
				padding: '8px 8px 8px 0px',
				boxSizing: 'border-box'
			},
			errorStyle: {
				color: '#B92D00',
				fontSize: 10
			},
			errorColor: {
				color: '#B92D00 !important'
			},
			errorBorder: {
				border: '1px solid #B92D00'
			},
			rootForm: {
				alignItems: 'end',
				marginRight: 0
			}
		}
	)
)

const CustomCheckBox = withStyles((theme) => ({
	root: {
		color: theme.palette.blue['60'],
		paddingTop: 0,
		'&$checked': {
			color: theme.palette.blue.main
		}
	},
	checked: {}
}))(MuiCheckbox)

interface AcceptingProps {
	name: string,
	label: string,
	color: string,
	textStyle: string,
}

type CheckBoxFieldProps = FieldRenderProps<string> & AcceptingProps

const CheckBoxField: React.FC<CheckBoxFieldProps> = (
	{
		input,
		meta: {
			touched,
			error
		},
		label,
		color,
		textStyle,
		name
	}
) => {
	const classes = useStyles()

	const checkboxFieldClass: ClassValue = classNames(
		classes.textFieldStyle,
		{
			[classes.errorBorder]: touched && error
		}
	)

	const checkBoxClass: ClassValue = classNames(
		classes[color as keyof typeof styles],
		classes[textStyle as keyof typeof styles]
	)
	return (
		<>
			<div className={checkboxFieldClass}>
				<FormControlLabel
					key={`checkbox-${name}`}
					control={
						<CustomCheckBox
							checked={input.checked}
							onChange={input.onChange}
							{...input}
						/>
					}
					label={label}
					classes={{
						root: classes.rootForm,
						label: checkBoxClass
					}}
				/>
			</div>

			{
				touched &&
				((error && <span className={classes.errorStyle}>{error}</span>))
			}
		</>
	)
}

export default CheckBoxField