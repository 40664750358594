import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../../styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(
    (theme) => ({
            ...styles(theme),
            circularProgressStyle: {
                width: '100%',
                height: '100%',
                marginBottom: 20,

                '& div': {
                    width: '100px !important',
                    height: '100px !important'
                }
            }
        }
    )
)

interface AcceptingProps {
    size?: number
}

const Loader: React.FC<AcceptingProps> = (
    {
        size
    }
) => {
    const classes = useStyles()

    const circularProgressClass: ClassValue = classNames(
        classes.flex,
        classes.alignCenter,
        classes.flexCenter,
        {
            [classes.circularProgressStyle]: !size
        }
    )

	return (
		<div className={circularProgressClass}>
			<CircularProgress size={size} />
		</div>
	)
}

export default Loader