import { getAuthClient } from './authClient'
import types from '../enums/fetchFactoryEnum'

const fetchFactory = (
	lien: string,
	config: any = {},
	type: types = types.json,
	donnees?: any,
    withoutToken: boolean = false
) => {
	const {
		headers,
		...others
	} = config
	const AuthClient = getAuthClient()

	return new Promise(
		(resolve, reject) => {
			// Vérification de la validité du token
			AuthClient.tokenManager.get('accessToken')
				.then(
					(token: any) => {
						// Si le token est valide, on initialise les headers et on fetch
						if (token || withoutToken) {
							const defaultHeaders: HeadersInit = {
								Accept: 'application/json',
							}

                            if (token) {
                                defaultHeaders.Authorization = "Bearer " + token.value
                            }

							const newConfig = {
								headers: {
									...defaultHeaders,
									...headers
								},
								...others
							}

							fetch(lien, newConfig)
								.then(
									response => {
										if (response.ok) {
											if (type === types.json) {
												response.json()
													.then(
														(data: any) => {
															resolve(data)
														}
													)
											}
											if (type === types.blob) {
												response.blob()
													.then(blob => {
														resolve(blob)
													})
											}
											if (type === types.response) {
												resolve(response)
											}
										} else {
											if (response.statusText === "Unauthorized" && response.status === 401) {
												AuthClient.signOut(
													{
														postLogoutRedirectUri: window.location.origin + "/login"
													}
												)
											} else {
												response.json()
													.then(
														(data: any) => {
															reject(data)
														}
													)
											}
										}
									}
								)
								.catch(
									(error: any) => {
										reject(error)
									}
								)
						} else {
							AuthClient.signOut(
								{
									postLogoutRedirectUri: window.location.origin + "/login"
								}
							)
						}
					}
				)
				.catch(
					(err: any) => {
						console.error("Une erreur est survenue lors de la récupération du token : ", err)
					}
				)
		}
	)
}

export const fetchFactoryDeconnectee = () => {

}

export default fetchFactory