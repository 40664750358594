import React, { useEffect, useRef, useState } from 'react'
import styles from '../../styles'
import { FormattedMessage, useIntl } from 'react-intl'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Field, Form } from 'react-final-form'
import FormTextField from '../../components/fields/FormTextField'
import Button from '../../components/button/Button'
import Link from '../../components/link/Link'
import { newPasswordModel, pwdRecoveryPostModel, recoveryTokenPostModel } from '../../types/logInModel'
import { isPasswordOk } from '../../utils/formUtils'
import { configEnum } from '../../enums/fetchFactoryEnum'
import pwdRecoveryStatusEnum from '../../enums/pwdRecoveryStatusEnum'
import links from '../../enums/linksEnum'
import { useHistory, useLocation } from 'react-router'
import Loader from '../../components/loader/Loader'
import ErrorCard from '../../components/errorCard/ErrorCard'

const TOKEN = 'recoveryToken'

const useStyle = makeStyles((theme) => ({
	...styles(theme),
	titleStyle: {
		padding: '15px 0px',
		display: 'flex',
		justifyContent: 'center'
	},
	fieldStyle: {
		paddingBottom: 30
	},
	linkStyle: {
		padding: '25px 0px'
	},
	betweenField: {
		paddingBottom: 15
	}
}))

const NewPassword: React.FC = () => {
	const classes = useStyle()
	const intl = useIntl()
	const history = useHistory()
	const location = useLocation()

	const fetchRef = useRef<Promise<any>>()
	const [stateToken, setStateToken] = React.useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)
	const [incorrectPwd, setIncorrectPwd] = useState<boolean>(false)
	const [seePassword, setSeePassword] = React.useState<boolean>(false)
	const [seeConfirmPassword, setSeeConfirmPassword] = React.useState<boolean>(false)

	const titleClass: ClassValue = classNames(
		'col-xs-12',
		classes.titleStyle,
		classes.heavy,
		classes.title4,
		classes.colorBlue
	)

	const containerErrorClass: ClassValue = classNames(
		'col-xs-12',
		classes.betweenField
	)

	useEffect(() => {
			const token = location.search.substr(1).split('=')
			if (token.length > 1) {
				if (token[1] && token[0] === TOKEN && !fetchRef.current) {
					setLoading(true)
					const dataTokenRecovery: recoveryTokenPostModel = {
						token: token[1]
					}

					fetchRef.current = fetch('/api/okta/recovery/token',
						{
							body: JSON.stringify(dataTokenRecovery),
							method: configEnum.post
						})
						.then(
							(response: Response) => response.json()
								.then(
									(tokenRecoveryResponse: any) => {
										if (tokenRecoveryResponse.status === pwdRecoveryStatusEnum.PASSWORD_RESET) {
											setStateToken(tokenRecoveryResponse.stateToken)
										}
									}
								)
						)
						.finally(
							() => {
								setLoading(false)
							}
						)
				}
			}
		}, [location.search]
	)

	const backToLogIn = () => {
		history.push(links.login)
	}

	const onSubmit = (values: newPasswordModel) => {

		const dataPwdRecovery: pwdRecoveryPostModel = {
			newPassword: values.newPassword,
			stateToken: stateToken
		}

		fetch('/api/okta/recovery/password',
			{
				body: JSON.stringify(dataPwdRecovery),
				method: configEnum.post
			})
			.then(
				(response: Response) => response.json()
					.then(
						(pwdRecoveryResponse: any) => {
							if (pwdRecoveryResponse.status === pwdRecoveryStatusEnum.SUCCESS) {
								history.push(links.login)
							} else {
								switch (pwdRecoveryResponse.status) {
									case pwdRecoveryStatusEnum.MFA_CHALLENGE:
										history.push(links.mfa, {
											mfaInfo: {
												email: pwdRecoveryResponse.email || '',
												verify_url: pwdRecoveryResponse.verify_url || '',
												state_token: pwdRecoveryResponse.state_token || '',
												factor_id: pwdRecoveryResponse.factor_id || '',
												status: pwdRecoveryResponse.status || ''
											},
											isMfaEnroll: false
										})
										break
									case pwdRecoveryStatusEnum.ERROR:
										setIncorrectPwd(true)
										break
								}
							}
						}
					)
			)

	}

	const validate = (
		values: newPasswordModel
	) => {
		const errors: any = {}

		if (!values.newPassword) {
			errors.newPassword = intl.formatMessage(
				{
					id: 'errors.required',
					defaultMessage: 'Requis*',
					description: 'Required message'
				}
			)
		}
		if (!values.repeatPassword) {
			errors.repeatPassword = intl.formatMessage(
				{
					id: 'errors.required',
					defaultMessage: 'Requis*',
					description: 'Required message'
				}
			)
		}

		if (values.newPassword) {
			if (!isPasswordOk(values.newPassword)) {
				errors.newPassword = intl.formatMessage(
					{
						id: 'errors.formatPassword',
						defaultMessage: 'Le mot de passe doit comporter au moins 8 caractères dont au moins un chiffre, une lettre minuscule et une lettre majuscule',
						description: 'Format password error'
					}
				)
			}
			if (values.repeatPassword && values.repeatPassword !== values.newPassword) {
				errors.repeatPassword = intl.formatMessage(
					{
						id: 'errors.samePassword',
						defaultMessage: 'Les nouveaux mots de passe doivent correspondre',
						description: 'Not same password error'
					}
				)
			}
		}
		return errors
	}

	return (
		<>
			{
				loading ?
					<Loader />
					:
					<div>
						<div className={titleClass}>
							<FormattedMessage
								id="resetPassword.title"
								defaultMessage="Réinitialiser votre mot de passe"
								description="Reset pwd title"
							/>
						</div>

						{
							incorrectPwd &&
							<div className={containerErrorClass}>
								<ErrorCard message={
									intl.formatMessage(
										{
											id: 'errors.newPwdKO',
											defaultMessage: 'Votre nouveau mot de passe ne doit pas être le même que votre mot de passe actuel et ne doit pas être un des anciens mots de passe utilisés',
											description: 'Error new password'
										}
									)
								} />
							</div>
						}

						<Form
							onSubmit={onSubmit}
							validate={validate}
							render={
								(
									{
										handleSubmit
									}
								) => (

									<form
										onSubmit={handleSubmit}
									>
										<div>
											<div className="col-xs-12">
												<div className={classes.betweenField}>
													<Field
														name="newPassword"
														type={seePassword ? 'text' : 'password'}
														component={FormTextField}
														showPassword
														seePassword={seePassword}
														handleClickShowPassword={() => setSeePassword(!seePassword)}
														placeholder={{
															id: 'resetPassword.newPassword',
															defaultMessage: 'Nouveau mot de passe',
															description: 'New password placeholder'
														}}
													/>
												</div>
												<div className={classes.fieldStyle}>
													<Field
														name="repeatPassword"
														type={seeConfirmPassword ? 'text' : 'password'}
														component={FormTextField}
														showPassword
														seePassword={seeConfirmPassword}
														handleClickShowPassword={() => setSeeConfirmPassword(!seeConfirmPassword)}
														placeholder={{
															id: 'resetPassword.repeatNewPassword',
															defaultMessage: 'Répétez le mot de passe',
															description: 'Repeat Password placeholder'
														}}
													/>
												</div>
												<Button
													background="backgroundBlue"
													color="colorWhite"
													textStyle="text8"
													type="submit"
													size="mediumButton"
												>
													<FormattedMessage
														id="resetPassword.reset"
														defaultMessage="Réinitialiser"
														description="Reset message"
													/>
												</Button>
											</div>
										</div>
									</form>
								)}
						/>

						<div className="col-xs-12">
							<div className={classes.linkStyle}>
								<Link
									textStyle="text8"
									color="colorBlue"
									onClick={backToLogIn}
								>
									<FormattedMessage
										id="resetPassword.backSignIn"
										defaultMessage="Retour à la connexion"
										description="Back to log in link"
									/>
								</Link>
							</div>
						</div>
					</div>
			}
		</>
	)
}

export default NewPassword