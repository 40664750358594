import React from 'react'
import { Radio } from '@material-ui/core'
import { FieldRenderProps } from 'react-final-form'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../../styles'

const useStyle = makeStyles((theme) => ({
	...styles(theme),
	root: {
		color: theme.palette.accessibility.main
	},
	colorSecondary: {
		color: theme.palette.accessibility.main,
		'&$checked': {
			color: theme.palette.accessibility.main
		}
	},
	checked: {}
}))

const FormRadioField: React.FC<FieldRenderProps<string>> = (
	{
		input: { checked, value, name, onChange, ...otherInput },
		meta,
		...other
	}
) => {
	const classes = useStyle()

	return (
		<Radio
			{...other}
			classes={{
				root: classes.root,
				colorSecondary: classes.colorSecondary,
				checked: classes.checked
			}}
			name={name}
			value={value}
			checked={checked}
			onChange={onChange}
			inputProps={otherInput}
		/>
	)
}
export default FormRadioField
