import React from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import { Card } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../../styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'

const useStyle = makeStyles((theme) => ({
	...styles(theme),
	iconErrorStyle: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#e34843'
	},
	errorMessage: {
		padding: 12,
		whiteSpace: 'break-spaces'
	}
}))

interface AcceptingProps {
	message: string
}

type ErrorCardProps = AcceptingProps

const ErrorCard: React.FC<ErrorCardProps> = (
	{
		message
	}
) => {
	const classes = useStyle()

	const errorIconClass: ClassValue = classNames(
		'col-xs-2',
		classes.iconErrorStyle
	)

	const errorMessageClass: ClassValue = classNames(
		'col-xs-10',
		classes.errorMessage,
		classes.colorBlue,
		classes.text3
	)

	return (
		<Card variant="outlined">
			<div className="row col-xs-12">
				<div className={errorIconClass}>
					<ErrorIcon style={{ color: 'white' }} />
				</div>
				<div className={errorMessageClass}>
					{message}
				</div>
			</div>
		</Card>
	)
}

export default ErrorCard