import flatten from 'flat'
import { ModelTranslations } from '../components/langContext/languagesModel'

const target = {
	authentification: {
		title: 'Log in to your space',
		placeholderEmail: 'Email address',
		placeholderPassword: 'Password',
		submit: 'Sign in',
		passwordForgotten: 'Forgot your password?',
		signUp: 'Not activated your account yet?',
		signUpAccount: 'Activate your customer space',
		noAccount: 'You don\'t have an account?',
		createAccount: 'Create your account'
	},
	resetPassword: {
		title: 'Reset your password',
		placeholderEmail: 'Email address',
		reset: 'Reset',
		backSignIn: 'Back to log in',
		emailSent: {
			title: 'Link sent!',
			description: 'An email has been sent to {email} with instructions on how to reset your password. Please ensure that the email address that you have entered matches the email address that you used to create your account.\''
		},
		newPassword: 'New password',
		repeatNewPassword: 'Repeat password'
	},
	activateAccount: {
		title: 'Activate your Customer Space',
		confirmEmail: 'Email confirmation',
		activationCode: 'Activation code',
		activate: 'Activate your account',
        helpSignIn: 'Login help',
		emailSent: {
			title: 'Verification email sent',
			description: 'To complete the creation of your account, please consult the message sent to your email address. By activating your account, you accept the general conditions of use of the Customer Area, accessible from the confirmation email. '
		},
        popinHelpLogIn: {
            title: 'Login help',
        },
	},
	finishActivation: {
		title: 'Finish the activation of your account',
		password: 'Password',
		confirmPassword: 'Confirm your password',
		invalidToken: 'This activation link has expired. Please renew the action.',
		accountActivated: 'Your account has been activated',
		errorUpdate: 'An error occurred while updating the password'
	},
	mfa: {
		title: 'Verify with email authentication',
		description: 'Send verification code to {email}',
		sendCode: 'Send code',
		disconnect: 'Disconnect',
		resendCode: 'Haven\'t received your code yet?',
		sendAgain: 'Send again',
		saveChoice: 'Remember my choice on this device',
		verificationCode: 'Verification Code',
		verify: 'Verify',
		checkEmail: 'A verification code has been sent to {email}. Check your emails and enter the code below.'
	},
	createAccount: {
		title: 'Create your YouFirst account',
		civility: 'Gender',
		firstname: 'Firstname',
		lastname: 'Name',
		email: 'Email',
		phone: 'Mobile phone',
		createBtn: 'Create your account',
		miss: 'Mrs',
		mister: 'Mr',
		seeRGPD: 'Personal data management',
		emailSent: {
			title: 'Email sent!',
			description: 'An email has been sent to {email} with instructions to complete the creation of your account. Double check that the email entered is correct.'
		},
		residence: {
			prospectType: {
				contact: 'Individual ',
				account: 'Professional'
			},
			socialReason: 'Corporate name',
			phone: 'Phone number'
		}
	},
	errors: {
		required: 'Required*',
		formatPassword: 'Your password must be at least 8 characters long, including at least one number, one lower-case letter and one upper-case letter',
		samePassword: 'New passwords must be the same',
		formatEmail: 'Incorrect email address format',
		notSameEmail: 'The email address that you have entered and the confirmation email address are different',
		loginFailed: 'Your login or your password is incorrect. Please try again',
		activationCodeKO: 'Your activation code does not match our registrations. Try Again',
		verificationCodeKO: 'Your access code does not match our records. Try Again',
		emailActivationKO: 'A user with this email already exists',
		newPwdKO: 'Your new password must not be the same as your current password and must not be one of the old passwords used',
		creationEmailCodeKO: 'An error has occurred; please get in touch with your YouFirst contact or try again later.',
		alreadyActive: 'Your account is already activated, please use the forgot password feature if necessary',
		oktaDefaultMessage: 'An error occurred while activating your account, please try again later',
		creationNonAuthorized: 'An error occurred while creating the account. \nPlease check your email address ',
		temporaryEmail: 'Please do not use a temporary email address for your YouFirst account',
		fullInbox: 'The e-mail indicated can not receive new messages',
		emailNotFound: 'The specified email was not found for this domain',
		invalidPhone: 'The phone number is not valid',
		inactiveUser: 'Your account is no longer active'
	},
	formulairePartenaire: {
		titre: 'EN_Complétez vos informations et activez votre compte YouFirst afin de prendre votre rendez-vous de visite.'
	}
}

export default flatten<typeof target, ModelTranslations>(target)
