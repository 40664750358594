import localStorageEnum from '../enums/localStorageEnum'
import { configEnum } from '../enums/fetchFactoryEnum'
import { ID_TOKEN_STORAGE_KEY } from '@okta/okta-auth-js'

const ID_TOKEN: string = 'id_token'
const ACCESS_TOKEN: string = 'accessToken'

const logout = (cookies: any, removeCookies: any) => {
	const oktaToken = JSON.parse(localStorage.getItem(localStorageEnum.oktaToken) || '{}')
	const commonUri = JSON.parse(localStorage.getItem(localStorageEnum.commonUri) || '{}')
	const dataLogout = {
		id_token: (oktaToken && oktaToken.accessToken && oktaToken.accessToken.id_token) || '',
		redirect: (commonUri && commonUri.login_uri) || ''
	}

	return fetch('/api/okta/logout',
		{
			body: JSON.stringify(dataLogout),
			method: configEnum.post
		})
		.then((response: Response) => response.json())
		.then(
			() => {
				if (cookies[ACCESS_TOKEN]) {
					const dataCheckSession = {
						redirect: (commonUri && commonUri.login_uri) || ''
					}
					return fetch('/api/okta/checkSession', {
						body: JSON.stringify(dataCheckSession),
						method: configEnum.post
					})
						.then(
							(response: Response) => response.json()
								.then(
									(logoutResponse: any) => {
										clearStorage(removeCookies)
										window.location.href = logoutResponse.url
									}
								)
						)
				}
			}
		)

}

export const clearStorage = (removeCookies?: any) => {
	localStorage.removeItem(localStorageEnum.user)
	localStorage.removeItem(localStorageEnum.oktaToken)
	localStorage.removeItem(localStorageEnum.accessToken)


	if (removeCookies) {
		removeCookies(ID_TOKEN, {
			path: '/',
			domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
		})
		removeCookies(ACCESS_TOKEN, {
			path: '/',
			domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
		})
		removeCookies(ID_TOKEN_STORAGE_KEY, {
			path: '/',
			domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
		})
	}
}

export default logout
