import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { configEnum } from '../../enums/fetchFactoryEnum'
import { useCookies } from 'react-cookie/cjs'
import links from '../../enums/linksEnum'
import { callbackPostModel } from '../../types/logInModel'
import Loader from '../../components/loader/Loader'
import localStorageEnum from '../../enums/localStorageEnum'

const CODE = 'code'
const ACCESS_TOKEN: string = 'accessToken'
const CODE_VERIFIER: string = 'code_verifier'

const CallbackLogin: React.FC = () => {
	const location = useLocation()
	const setCookie = useCookies([ACCESS_TOKEN])[1]
	const history = useHistory()
	const [loading, setLoading] = useState<boolean>(false)

	useEffect(() => {
			const hash = location.hash.split('&')
			const code = hash[0].substr(1).split('=')

			if (code.length > 1 && code[1] && code[0] === CODE) {
				setLoading(true)
				const dataCallback: callbackPostModel = {
					code: code[1],
					code_verifier: localStorage.getItem(CODE_VERIFIER) || ''
				}
				fetch('/api/okta/callback',
					{
						body: JSON.stringify(dataCallback),
						method: configEnum.post
					})
					.then(
						(response: Response) => response.json()
							.then(
								(callbackResponse: any) => {
									setCookie(ACCESS_TOKEN, callbackResponse, {
										path: '/',
										domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || '',
										secure: true
									})
									localStorage.setItem(localStorageEnum.accessToken, JSON.stringify(callbackResponse))
									history.push(links.checkOkta)
								}
							)
					)
					.finally(
						() => setLoading(false)
					)
			}
		},
		[location, history, setCookie]
	)
	return (
		<>
			{
				loading && <Loader />
			}
		</>
	)
}

export default CallbackLogin
